<template>
    <div class="rechargeManagement-detail">
        <app-header :title="title" :isShowBack="true"></app-header>
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    export default {
        data() {
            let _this = this;
            return {
                title: "充值记录",
                opt: {
                    search: [{
                        key: "rechargeTime",
                        label: "充值时间",
                        type: "data-range"
                    }],
                    columns: [{
                            label: "商家名称",
                            key: "bizUserName"
                        },{
                            label: "充值单号",
                            key: "orderNo"
                        },{
                            label: "充值金额",
                            key: "amount"
                        },
                        {
                            label: "到账金额",
                            key: "realAmount"
                        },
                        {
                            label: "充值地址（浏览器打开）",
                            key: "url",
                            width: 220,
                            opt: {
                                isUserPopover: true
                            }
                        },
                        {
                            label: "充值时间",
                            key: "startTime"
                        },
                        {
                            label: "到账时间",
                            key: "realTime"
                        },
                        {
                            label: "手续费",
                            key: "fee"
                        },
                        {
                            label: "状态",
                            key: "stateStr"
                        }
                    ],
                    buttons: []
                },
            };
        },
        created() {},
        deactivated() {},
        activated() {},
        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    bizUserId: this.$route.params.id,
                    startTime: opt.searchForm['rechargeTime'] ? opt.searchForm['rechargeTime'][0] : null,
                    endTime: opt.searchForm['rechargeTime'] ? opt.searchForm['rechargeTime'][1] : null,
                    ...opt.searchForm
                };
                delete dto.rechargeTime;
                delete dto.receiptTime;
                this.post("/mall-service/allinpay/v1/recharge/page", dto, {
                    isUseResponse: false
                }).then(res => {
                    if (res.data && res.data.length > 0) {
                        res.data.forEach(item => {
                            item.stateStr = ['已提交申请', '发起充值', '充值成功', '充值中', '充值失败'][item.state - 1];
                            item.fee = item.fee ? Number(item.fee).toFixed(2) : '0.00';
                            item.amount = item.amount ? Number(item.amount).toFixed(2) : '0.00'
                            item.realAmount = item.realAmount ? Number(item.realAmount).toFixed(2) : '0.00';
                            item.realTime = item.realTime ? item.realTime : '--';
                        });
                    }
                    opt.cb(res);
                });
            },
        }
    };
</script>
<style lang="scss" scoped>
.rechargeManagement-detail {
    height: calc(100% - 78px);
}
</style>